import { createSlice } from "@reduxjs/toolkit";
import { HandleServerResponse } from "../../helpers/Common";
import { CallStatus, UserStatus } from "../../helpers/Constant";
import { CallService } from "../../services/CallService";
import { QueueService } from "../../services/QueueService";
import { UserService } from "../../services/UserService";

const wallboardTimeoutInterval = 5000 // 5 seconds

export const InteractionSlice = createSlice({
    name: 'interaction',
    initialState: {
        waitingCalls: [],
        ivrCalls: [],
        activeCalls: [],
        wrapupCalls: [],
        calls: {},

        users: {},
        availableUsers: [],
        inaCallUsers: [],
        wrapupUsers: [],
        awayUsers: [],
        onBreakCustom1Users: [],
        onBreakCustom2Users: [],
        offlineUsers: [],
        workOfflineUsers: [],
        workOfflineCustom1Users: [],
        workOfflineCustom2Users: [],

        userStats: {
            activeCalls: 0,
            activeTime: 0,
            failedCalls: 0,
            inviteCalls: 0,
            shortCalls: 0,
            wrapupTime: 0,
            wrapups: 0
        },

        queueStats: {},

        wallboard: {
            open: false,
            initialized: false,
            timer: null,
            loading: false,
            stats: {
                calls: [],
                callSummary: null,
                chats: [],
                emails: [],
            }
        },

        queues: {},

        agents: {},

        message: null,

        integrations: {},

        callback: null,

        queueSummary: {
            stats: [],
            lastUpdated: null
        },
    },
    reducers: {
        updateCallData: (state, action) => {
            let chainId = null;
            let oldStatus = null;
            let oldLen = 0;

            switch (action.payload.type) {
                case CallStatus.waiting:
                    chainId = action.payload.data.chainId;
                    oldStatus = ((chainId in state.calls) ? state.calls[chainId] : null);

                    //Check duplicates
                    if (oldStatus !== CallStatus.waiting && oldStatus !== CallStatus.active) {
                        state.calls[chainId] = CallStatus.waiting;
                        action.payload.data.type = CallStatus.waiting;
                        state.waitingCalls.push(action.payload.data);

                        let ivrCall = state.ivrCalls.find(x => x.chainId === chainId)
                        if (ivrCall ?? false) {
                            state.ivrCalls = state.ivrCalls.filter(call => call.chainId !== chainId);
                        }
                    }

                    break;
                case CallStatus.active:
                    chainId = action.payload.data.chainId;
                    oldStatus = ((chainId in state.calls) ? state.calls[chainId] : null);

                    //Check duplicates
                    if (oldStatus !== CallStatus.active) {
                        state.calls[chainId] = CallStatus.active;

                        action.payload.data.type = CallStatus.active;
                        state.activeCalls.push(action.payload.data);

                        state.waitingCalls = state.waitingCalls.filter(call => call.chainId !== chainId);
                    }

                    break;
                case CallStatus.waitingupdate:
                    chainId = action.payload.data.chainId;

                    if (!action.payload.data.isOldQueue) {
                        const callIdx = state.waitingCalls.map(function (call) { return call.chainId; }).indexOf(chainId);

                        if (callIdx !== -1) {
                            state.waitingCalls[callIdx]["queueId"] = action.payload.data.queueId;
                        }
                    }

                    break;
                case CallStatus.update:
                    chainId = action.payload.data.chainId;
                    oldStatus = ((chainId in state.calls) ? state.calls[chainId] : null);

                    //find index
                    const callIdx = state.activeCalls.map(function (call) { return call.chainId; }).indexOf(chainId);
                    if (callIdx === -1) {
                        //Add new if not exist
                        state.calls[chainId] = CallStatus.active;

                        action.payload.data.type = CallStatus.active;
                        state.activeCalls.push(action.payload.data);

                        state.waitingCalls = state.waitingCalls.filter(call => call.chainId !== chainId);
                    } else {
                        //Update data
                        action.payload.data.type = CallStatus.active;
                        state.activeCalls[callIdx] = action.payload.data;
                    }

                    break;
                case CallStatus.abandoned:
                    chainId = action.payload.data.chainId;
                    oldLen = state.waitingCalls.length;
                    state.waitingCalls = state.waitingCalls.filter(call => call.chainId !== chainId);
                    delete state.calls[chainId];

                    break;
                case CallStatus.decline:
                    chainId = action.payload.data.chainId;
                    oldLen = state.waitingCalls.length;
                    state.waitingCalls = state.waitingCalls.filter(call => call.chainId !== chainId);
                    delete state.calls[chainId];

                    break;
                case CallStatus.complete:
                    chainId = action.payload.data.chainId;
                    state.activeCalls = state.activeCalls.filter(call => call.chainId !== chainId);
                    delete state.calls[chainId];

                    break;
                case CallStatus.wrapupstart:
                    chainId = action.payload.data.chainId;
                    state.activeCalls = state.activeCalls.filter(call => call.chainId !== chainId);
                    delete state.calls[chainId];

                    const wrapup = state.wrapupCalls.filter(wrapup => wrapup.userId === action.payload.data.userId);

                    //Check duplicates
                    if (wrapup.length === 0) {
                        state.wrapupCalls.push(action.payload.data);
                    }

                    break;
                case CallStatus.wrapupend:
                    state.wrapupCalls = state.wrapupCalls.filter(wrapup => wrapup.userId !== action.payload.data.userId);

                    break;
                case CallStatus.ringingstart:
                    chainId = action.payload.data.chainId;

                    const waitingRingingIdx = state.waitingCalls.map(function (call) { return call.chainId; }).indexOf(chainId);

                    if (waitingRingingIdx !== -1) {
                        state.waitingCalls[waitingRingingIdx]["ringing"] = action.payload.data;
                    }

                    break;
                case CallStatus.ringingend:
                    chainId = action.payload.data.chainId;

                    const waitingRingingRmIdx = state.waitingCalls.map(function (call) { return call.chainId; }).indexOf(chainId);

                    if (waitingRingingRmIdx !== -1) {
                        state.waitingCalls[waitingRingingRmIdx]["ringing"] = null;
                    }

                    break;
                default:
                    console.error("Unhandled type", action.payload.call);

                    break;
            }
        },
        setWaitingCalls: (state, action) => {
            action.payload.forEach(item => {
                if (!(item.chainId in state.calls)) {
                    state.calls[item.chainId] = CallStatus.waiting;
                    item.type = CallStatus.waiting;
                    state.waitingCalls.push(item);
                }
            });
        },
        updateWaitingCall: (state, action) => {
            const waitingCallIndex = state.waitingCalls.map(function (call) { return call.chainId; }).indexOf(action.payload.chainId);

            if (waitingCallIndex !== -1) {
                state.waitingCalls[waitingCallIndex]["transferring"] = action.payload.transferring ?? false;
            }
        },
        setIvrCalls: (state, action) => {
            state.ivrCalls = [];
            action.payload.forEach(item => {
                item.type = CallStatus.ivr;
                state.ivrCalls.push(item);
            });
        },
        addIvrCall: (state, action) => {
            let ivrCallList = [...state.ivrCalls];
            let isExist = ivrCallList.find(x => x.chainId === action.payload.chainId);

            if (isExist === undefined) {
                let item = action.payload;
                item.type = CallStatus.ivr;
                state.ivrCalls.push(item);
            }
        },
        removeIvrCall: (state, action) => {
            let ivrCallList = [...state.ivrCalls];
            let filteredList = ivrCallList.filter(x => x.chainId !== action.payload.chainId);
            state.ivrCalls = [...filteredList];
        },
        removeWaitingCall: (state, action) => {
            let callList = [...state.waitingCalls];
            let filteredList = callList.filter(x => x.chainId !== action.payload.chainId);
            state.waitingCalls = [...filteredList]
        },
        setActiveCalls: (state, action) => {
            action.payload.forEach(item => {
                if (!(item.chainId in state.calls)) {
                    state.calls[item.chainId] = CallStatus.active;
                    item.type = CallStatus.active;
                    state.activeCalls.push(item);
                }
            });
        },
        updateActiveCall: (state, action) => {
            const activeCallIndex = state.activeCalls.map(function (call) { return call.chainId; }).indexOf(action.payload.chainId);

            if (activeCallIndex !== -1) {
                state.activeCalls[activeCallIndex]["transferring"] = action.payload.transferring ?? false;
            }
        },
        removeActiveCall: (state, action) => {
            let callList = [...state.activeCalls];
            let filteredList = callList.filter(x => x.chainId !== action.payload.chainId);
            state.activeCalls = [...filteredList]
        },
        setWrapupCalls: (state, action) => {
            action.payload.forEach(item => {
                state.wrapupCalls.push(item);
            });
        },
        resetCalls: (state) => {
            state.calls = {};
            state.waitingCalls = [];
            state.activeCalls = [];
            state.wrapupCalls = [];
        },
        updateUserAvailability: (state, action) => {
            const userId = action.payload.userId;

            if (!(userId in state.users)) {
                state.users[userId] = { status: null, queues: action.payload.queues };
            } else {
                state.users[userId].queues = action.payload.queues;
            }

            if (state.users[userId].status !== action.payload.availability) {
                //Remove from old list
                switch (state.users[userId].status) {
                    case UserStatus.available:
                        state.availableUsers = state.availableUsers.filter(user => user.id !== userId);
                        break;
                    case UserStatus.inaCall:
                        state.inaCallUsers = state.inaCallUsers.filter(user => user.id !== userId);
                        break;
                    case UserStatus.onBreak:
                        state.awayUsers = state.awayUsers.filter(user => user.id !== userId);
                        break;
                    case UserStatus.workOffline:
                        state.workOfflineUsers = state.workOfflineUsers.filter(user => user.id !== userId);
                        break;
                    case UserStatus.onBreakCustom1:
                        state.onBreakCustom1Users = state.onBreakCustom1Users.filter(user => user.id !== userId);
                        break;
                    case UserStatus.onBreakCustom2:
                        state.onBreakCustom2Users = state.onBreakCustom2Users.filter(user => user.id !== userId);
                        break;
                    case UserStatus.workOfflineCustom1:
                        state.workOfflineCustom1Users = state.workOfflineCustom1Users.filter(user => user.id !== userId);
                        break;
                    case UserStatus.workOfflineCustom2:
                        state.workOfflineCustom2Users = state.workOfflineCustom2Users.filter(user => user.id !== userId);
                        break;
                    case UserStatus.wrapUp:
                        state.wrapupUsers = state.wrapupUsers.filter(user => user.id !== userId);
                        break;
                    case UserStatus.offline:
                        state.offlineUsers = state.offlineUsers.filter(user => user.id !== userId);
                        break;
                    default:
                        break;
                }

                //Add to new list
                switch (action.payload.availability) {
                    case UserStatus.available:
                        state.availableUsers.push({ id: userId });
                        break;
                    case UserStatus.inaCall:
                        state.inaCallUsers.push({ id: userId });
                        break;
                    case UserStatus.onBreak:
                        state.awayUsers.push({ id: userId });
                        break;
                    case UserStatus.workOffline:
                        state.workOfflineUsers.push({ id: userId });
                        break;
                    case UserStatus.onBreakCustom1:
                        state.onBreakCustom1Users.push({ id: userId });
                        break;
                    case UserStatus.onBreakCustom2:
                        state.onBreakCustom2Users.push({ id: userId });
                        break;
                    case UserStatus.workOfflineCustom1:
                        state.workOfflineCustom1Users.push({ id: userId });
                        break;
                    case UserStatus.workOfflineCustom2:
                        state.workOfflineCustom2Users.push({ id: userId });
                        break;
                    case UserStatus.wrapUp:
                        state.wrapupUsers.push({ id: userId });
                        break;
                    case UserStatus.offline:
                        state.offlineUsers.push({ id: userId });
                        break;
                    default:
                        break;
                }

                state.users[userId].status = action.payload.availability;
            }
        },
        setUserAvailability: (state, action) => {
            const queues = action.payload.queues;
            action.payload.users.forEach(item => {
                //Make sure user is not added from another SignalR message after fetching data or user is in my queues
                let queueIds = [];

                Object.entries(item.queues).forEach(([key]) => {
                    queueIds.push(key);
                });

                if (!(item.userId in state.users) && (queueIds || []).filter(x => queues.includes(x)).length > 0) {
                    state.users[item.userId] = { status: item.availability, queues: item.queues };

                    switch (item.availability) {
                        case UserStatus.available:
                            state.availableUsers.push({ id: item.userId });
                            break;
                        case UserStatus.inaCall:
                            state.inaCallUsers.push({ id: item.userId });
                            break;
                        case UserStatus.onBreak:
                            state.awayUsers.push({ id: item.userId });
                            break;
                        case UserStatus.workOffline:
                            state.workOfflineUsers.push({ id: item.userId });
                            break;
                        case UserStatus.onBreakCustom1:
                            state.onBreakCustom1Users.push({ id: item.userId });
                            break;
                        case UserStatus.onBreakCustom2:
                            state.onBreakCustom2Users.push({ id: item.userId });
                            break;
                        case UserStatus.workOfflineCustom1:
                            state.workOfflineCustom1Users.push({ id: item.userId });
                            break;
                        case UserStatus.workOfflineCustom2:
                            state.workOfflineCustom2Users.push({ id: item.userId });
                            break;
                        case UserStatus.wrapUp:
                            state.wrapupUsers.push({ id: item.userId });
                            break;
                        case UserStatus.offline:
                            state.offlineUsers.push({ id: item.userId });
                            break;
                        default:
                            break;
                    }
                }
            });
        },
        resetUserAvailability: (state) => {
            state.users = {};
            state.availableUsers = [];
            state.inaCallUsers = [];
            state.wrapupUsers = [];
            state.workOfflineUsers = [];
            state.awayUsers = [];
            state.offlineUsers = [];
            state.onBreakCustom1Users = [];
            state.onBreakCustom2Users = [];
            state.workOfflineCustom1Users = [];
            state.workOfflineCustom2Users = [];
            state.integrations = {};
        },
        updateQueueStats: (state, action) => {
            if (action.payload.queueId in state.queueStats) {
                state.queueStats[action.payload.queueId] = { ...state.queueStats[action.payload.queueId], ...action.payload.stats };
            }
        },
        updateMultiQueueStats: (state, action) => {
            let stats = {};
            for (const [key, value] of Object.entries(action.payload)) {
                const parts = key.split(':')

                if (parts[0] in state.queueStats) {
                    if (!(parts[0] in stats)) {
                        stats[parts[0]] = {};
                    }

                    stats[parts[0]][parts[1]] = value;
                }
            }

            for (const [key, value] of Object.entries(stats)) {
                state.queueStats[key] = { ...state.queueStats[key], ...value };
            }
        },
        setQueueStats: (state, action) => {
            let stats = {};
            action.payload.forEach(item => {
                stats[item.id] = item;
            });
            state.queueStats = stats;
        },
        resetQueueStats: (state, action) => {
            state.queueStats = {};
        },
        updateMyStats: (state, action) => {
            state.userStats = action.payload;
        },
        AddAgentToAgents: (state, action) => {
            state.agents[action.payload.id] = action.payload.data;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        clearMessage: (state) => {
            state.message = null;
        },
        AddIntegrationUser: (state, action) => {
            state.integrations[action.payload.chainId] = action.payload.integrationId;
        },
        setCallback: (state, action) => {
            state.callback = action.payload;
        },
        setQueueSummaryStats: (state, action) => {
            state.queueSummary = { stats: action.payload, lastUpdated: new Date() }
        },
        setWallboardInteractionStats: (state, action) => {
            state.wallboard.stats = action.payload;
            state.wallboard.loading = false;
        },
        setWallboardMetadata: (state, action) => {
            state.wallboard = {...state.wallboard, ...action.payload};
        },
        wallboardLoading: (state, action) => {
            state.wallboard.loading = true;
        },
    },
});

export const {
    updateCallData, setWaitingCalls, updateWaitingCall, removeWaitingCall, setActiveCalls, updateActiveCall, removeActiveCall, setWrapupCalls, resetCalls,
    updateUserAvailability, setUserAvailability, resetUserAvailability,
    updateQueueStats, updateMultiQueueStats, setQueueStats, resetQueueStats,
    updateMyStats, AddAgentToAgents, setMessage, clearMessage, AddIntegrationUser,
    setIvrCalls, addIvrCall, removeIvrCall, setCallback, setQueueSummaryStats,
    setWallboardInteractionStats, setWallboardMetadata, wallboardLoading
} = InteractionSlice.actions;

export const loadWallboardData = () => async  (dispatch) => {
    const data = await QueueService.getWallboardStats();
    dispatch(setWallboardInteractionStats(data.data));
}

export const wallboardInteractionStats = (openWallboard=false, reload=false) => async  (dispatch, getState) => {
    let wallboard = {...getState().interaction?.wallboard};
    let authWallboard = {...getState().auth?.wallboard};

    if(!wallboard?.open && !openWallboard && !reload && !authWallboard.centrepal)
        return;

    // load initial data
    if(openWallboard){
        dispatch(wallboardLoading());
        dispatch(loadQueueStats());
        dispatch(loadWallboardData());
    }

    if(!openWallboard && wallboard?.timer === null){
        let timer = setTimeout(() => {
            dispatch(loadWallboardData());
            dispatch(setWallboardMetadata({
                timer: null
            }));
        },wallboardTimeoutInterval);

        dispatch(setWallboardMetadata({
            open: true,
            initialized: true,
            timer: timer,
        }))
    }
}

export const loadQueueStats = () => async  (dispatch) => {
    dispatch(resetQueueStats());
    const data = await QueueService.queueStats();
    HandleServerResponse(data, "", dispatch);
}

export const loadUserAvailability = () => async dispatch => {
    dispatch(resetUserAvailability());
    const data = await UserService.dashboardStats();
    HandleServerResponse(data, "", dispatch);
}

export const loadCalls = () => async dispatch => {
    dispatch(resetCalls());
    const data = await CallService.dashboardStats();
    HandleServerResponse(data, "", dispatch);
}

export const loadMyStats = () => async (dispatch, getState) => {
    const data = await UserService.myStats();
    HandleServerResponse(data, "", dispatch);

    //call queue stats summary loading api to prevent signalR issue initial loading
    if(getState().interaction.queueSummary?.stats?.length === 0){
        await UserService.queueStats();
    }
}

export const selectActiveCallTrasfer = (state, key) => {
    if (key) {
        let call = state.interaction.activeCalls.find(x => x.chainId === key);

        if (call) {
            return call?.transferring ?? false;
        }
    }
    return false;
}

export default InteractionSlice.reducer;