import axios from 'axios'

//Queue APIs (settings)

const getAll = async (values) => {
    const { data } = await axios.get('/api/queue/list', { params: values }).then(result => result);
    return data;
};

const disableQueue = async (queueId) => {
    const { data } = await axios.delete(`/api/queue/disable/${queueId}`).then(result => result);
    return data;
};

const enableQueue = async (id) => {
    const { data } = await axios.post(`api/queue/enable/${id}`).then(result => result);
    return data;
};

const save = async (values) => {
    const { data } = await axios.post('/api/queue', values).then(result => result);
    return data;
};

const get = async (id = null) => {
    const { data } = await axios.get(`/api/queue/${id}`).then(result => result);
    return data;
};

const update = async (values) => {
    const { data } = await axios.put('/api/queue', values).then(result => result);
    return data;
};

//Queue user APIs (settings)

const getAllocatedUsers = async (queueId, values) => {
    const { data } = await axios.get(`/api/queue/${queueId}/users`, { params: values }).then(result => result);
    return data;
};

const getUnassignUsers = async (queueId) => {
    const { data } = await axios.get(`/api/queue/${queueId}/unassignedusers`).then(result => result);
    return data;
};

const assignUsersToQueue = async (values) => {
    const { data } = await axios.post(`api/queue/${values.queueId}/users`, values).then(result => result);
    return data;
};

const removeAssignUser = async (values) => {
    const { data } = await axios.delete(`/api/queue/${values.queueId}/user/${values.userId}`).then(result => result);
    return data;
};

const queueStats = async (values) => {
    const { data } = await axios.get('/api/queue/stats').then(result => result);
    return data;
};

const getTenantApplicationUsers = async (values) => {
    const { data } = await axios.get('/api/queue/tenantapplicationusers').then(result => result);
    return data;
};

const emergencyMode = async (values) => {
    const { data } = await axios.post(`api/queue/emergencymode`, values).then(result => result);
    return data;
};

const callFlows = async (queueId) => {
    const { data } = await axios.get(`api/queue/${queueId}/callflows`).then(result => result);
    return data;
};

const getSearchUsers = async (name) => {
    const { data } = await axios.get(`/api/queue/tenantusers/${name}`,).then(result => result);
    return data;
}

const getSearchGroups = async (name) => {
    const { data } = await axios.get(`/api/queue/tenantgroups/${name}`,).then(result => result);
    return data;
}

const getSearchUserOrGroup = async (id, group = false) => {
    const { data } = await axios.get(`/api/queue/tenantuserorgroup/${id}/${group}`,).then(result => result);
    return data;
}

const getSmsSenders = async (type) => {
    const { data } = await axios.get(`/api/queue/smssenders/${type}`,).then(result => result);
    return data;
}

const remove = async (id) => {
    const { data } = await axios.delete(`api/queue/${id}`).then(result => result);
    return data;
};

const getMessageTemplate = async (id) => {
    const { data } = await axios.get(`/api/queue/messagetemplate/${id}`,).then(result => result);
    return data;
};

const duplicate = async (id) => {
    const { data } = await axios.post(`api/queue/duplicate/${id}`,).then(result => result);
    return data;
};

const queueLeaderboardStats = async (values) => {
    const { data } = await axios.post('/api/queue/leaderboard', values).then(result => result);
    return data;
};

const getQueuesWithOutboundNumber = async () => {
    const { data } = await axios.get(`/api/queue/queueswithoutboundnumber`,).then(result => result);
    return data;
};

const getWallboardQueueData = async () => {
    const { data } = await axios.get(`/api/queue/wallboard`,).then(result => result);
    return data;
};

//wallboard stats
const getWallboardStats = async (id = null) => {
    const { data } = await axios.get(`/api/queue/wallboardStats`).then(result => result);
    return data;
};

//conversation
const getValidQueues = async (id = null) => {
    const { data } = await axios.get(`/api/queue/queues/${id}`).then(result => result);
    return data;
};

export const QueueService = {
    //queue settings
    getAll,
    get,
    save,
    update,
    disableQueue,
    enableQueue,
    //queue-user settings
    getAllocatedUsers,
    getUnassignUsers,
    assignUsersToQueue,
    removeAssignUser,
    //wallboard
    queueStats,
    getTenantApplicationUsers,
    emergencyMode,
    callFlows,
    getSearchUsers,
    getSearchGroups,
    getSearchUserOrGroup,
    remove,
    getSmsSenders,
    getMessageTemplate,
    duplicate,
    queueLeaderboardStats,
    getQueuesWithOutboundNumber,
    getWallboardQueueData,
    getWallboardStats,
    //conversation
    getValidQueues,

};