import React from 'react'
import { useTheme } from '@mui/styles';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import GroupBox from '../GroupBox';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

const cutout = "75%",
    fontColor = '#000',
    legend = {
        padding: 15,
        boxWidth: 10,
        boxHeight: 10
    },
    tooltip = {
        fontSize: 12,
    }

const DoughnutChart =(
    {
        id,
        labels = [],
        values = [],
        bgColors = [],
        total,
        // showCenter = false,
        // centerTextSize = 2,
        width = 320,
        height = 190,
        loading=false
    }) => {
    const theme = useTheme();
    const ref = React.useRef(null)
    Chart.register(ArcElement, Tooltip, Legend);
    const { t } = useTranslation();

    // const centerText = {
    //     id: `center-text-${id}`,
    //     afterDatasetDraw: ({ ctx, chartArea: { width, height } }) => {
    //         ctx.save();
    //         ctx.font = `bold ${centerTextSize}em Arial`;
    //         ctx.fillStyle = theme.palette.black.main;
    //         ctx.textAlign = "center";
    //         ctx.fillText(total, width / 2, (height / 2));
    //     }
    // }

    const data = {
        labels: labels.map((element, index) => {
            return t(element);
          }),
        datasets: [{
            data: values,
            backgroundColor: bgColors,
            hoverOffset: 4
        }]
    };

    const options = {
        cutout: cutout,
        responsive: false,
        aspectRatio: 2,
        // animation: false,
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: theme.palette.mode === "light" ? 'black' : "white",
                    padding: legend.padding,
                    boxWidth: legend.boxWidth,
                    boxHeight: legend.boxHeight,
                    usePointStyle: true,
                },
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                    title: (titleData) => {
                        let { dataIndex, dataset: { data: currentData } } = titleData[0];

                        if (total === 0) {
                            return t("No calls")
                        }

                        return `${currentData[dataIndex]} call(s)`;
                    },
                    label: ({ dataIndex, dataset: { data: currentData } }) => {
                        if (total === 0) {
                            return '100%'
                        }

                        return `${Math.round((currentData[dataIndex] / total) * 100)}%`;
                    },
                },
                backgroundColor: theme.palette.grey.dark,
                titleColor: fontColor,
                titleFontSize: tooltip.fontSize,
                bodyColor: fontColor,
                bodyFontSize: tooltip.fontSize,
                bodyAlign: 'center',
                displayColors: false
            }
        },
    };

    // const plugins = showCenter ? [centerText] : [];

    React.useEffect(() => {
        if(ref?.current !== null){
            ref.current.data.datasets[0].data = values
        }
    },[values])

    return (
        <GroupBox flex center gutter showDivider={false}>
            {
                loading
                    ? <Skeleton variant="circular" width={120} height={120} />
                    : <Doughnut
                        id={`doughnut-${id}`}
                        ref={ref}
                        data={data}
                        options={options}
                        height={height}
                        width={width}
                    />
            }
        </GroupBox>
    )
}

export default DoughnutChart;