import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Typography, Box } from '@mui/material';
import { Call, CallReceived, CallEnd, CallMissed, AddIcCall, Block } from '@mui/icons-material';
import GroupBox from '../../../components/GroupBox';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(1),
        width: '140px',
        flexGrow: 0,
        '& .caption': {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '2px',
            '& .MuiSvgIcon-root': {
                fontSize: '1rem',
                marginRight: '2px',
            },
        },
        '& h1.answered': {
            color: theme.palette.info.main,
        },
        '& h1.abandon': {
            color: theme.palette.error.main,
        },
        '& h1.inacall': {
            color: theme.palette.success.main,
        },
        '& h1.waiting': {
            color: theme.palette.warning.main,
        },
        '& h1.decline': {
            color: theme.palette.text.disabled,
        },
    },
}));

export default function CallSummary(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const icons = {
        total: <CallReceived />,
        answered: <CallEnd />,
        abandon: <CallMissed />,
        inacall: <Call />,
        waiting: <AddIcCall />,
        decline: <Block />,
    };

    const items = [
        { key: "total", name: "Total Calls", count: props?.totInteractions ?? 0 },
        { key: "answered", name: "Total Answered", count: props?.totCompleted ?? 0 },
        { key: "inacall", name: "In a Call", count: props?.totActive ?? 0 },
        { key: "waiting", name: "Call Waiting", count: props?.totWaiting ?? 0 },
        { key: "abandon", name: "Abandon Calls", count: props?.totAbandoned ?? 0 },
        { key: "decline", name: "Decline Call", count: props?.totDeclined ?? 0 }
    ];

    const getIcon = (key) => {
        return icons[key];
    }
    return (
        <GroupBox title={t("Daily Call Summary")} flex center gutter>
            {items.map((item, index) => (
                <Box className={classes.item} textAlign="center" key={index}>
                    <Typography variant="h1" classes={{ "h1": item.key }}>{item.count}</Typography>
                    <Divider light variant="middle" />
                    <div className="caption" display="flex">
                        {getIcon(item.key)}
                        <Typography variant="caption" component="div" display="inline">{t(item.name)}</Typography>
                    </div>
                </Box>
            ))}
        </GroupBox>
    );
}