import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';


import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
    dialogContent: {
        maxWidth: '100%'
    },
}));

export default function ConfirmationDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const {open, handleClose, confirmChange, header, message} = props;
    const { t } = useTranslation();

    const styles = {
        actions: {
            margin: theme.spacing(1),
        },
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
                <DialogContent>
                    <div className={classes.dialogContent}>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                    </div>
                </DialogContent>
                <DialogActions sx={styles.actions}>
                    <Button id='confirm-action-yes' onClick={confirmChange} variant="contained" disableElevation>
                        {t("Yes")}
                    </Button>
                    <Button id='confirm-action-no' onClick={handleClose} variant="contained" color="grey" disableElevation>
                        {t("No")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmationDialog.defaultProps = {
    header: "",
    message: "",
};