import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Table, TableBody, TableRow, TableCell, TableHead, Typography, Stack, Skeleton } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    warning: {
        color: theme.palette.warning.main,
    },
    info: {
        color: theme.palette.info.main,
    },
    errorAnimation: {
        animation: "$blinker 1s linear infinite",
        color: theme.palette.error.main,
    },
    "@keyframes highlighter": {
        '0%': {
          filter: "drop-shadow(0px 0px 1px)"
        },
        '35%': {
          filter: "drop-shadow(0px 0px 3px)"
        },
        '70%': {
          filter: "drop-shadow(0px 0px 6px)"
        },
        '100%': {
          filter: "drop-shadow(0px 0px 3px)"
        },
      },
      "@keyframes blinker": {
        from: {
          opacity: 0.2,
        },
        to: {
          opacity: 1.5,
        },
      },
}));

export default function WbQueues({ queueStats , queues, queueUserStats, loading=false}) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    
    const styles = {
        headerCell: {
            fontWeight: 500,
            backgroundColor: theme.palette.background.default
        },
    }

    const getClassName = (sla, warning) => {
        //default warning period is 5 percent and warning period ignotred if the configured SLA warning is greater than 90
        let slaWarningPeriod = warning >= 90 ? 0 : 5;

        if ((sla > (warning + slaWarningPeriod) || sla === 'N/A')) {
            return classes.info;
        } else if (slaWarningPeriod > 0 && sla <= (warning + slaWarningPeriod) && sla >= warning) { 
            return classes.warning;
        } else { 
            return classes.errorAnimation;
        }
    }

    const getQueueSLA = (stats, formular) => {

        if (formular === 0) return "N/A";

        let {
            totWaitingAnsweredWithinSLA,
            totWaitingAnswered,
            totCallback,
            totDeclined,
            totTransfered,
            totShortAbandoned,
            totAbandoned
        } = stats;

        if (totWaitingAnsweredWithinSLA === 0) return 0;

        let tot = 0;
        let flowouts = totCallback + totDeclined + totTransfered;

        switch (formular) {
            case 1:
                tot = totWaitingAnswered + totAbandoned;
                return tot === 0 ? 100 : Math.floor(totWaitingAnsweredWithinSLA / tot * 100);
        
            case 2:
                tot = totWaitingAnswered + totAbandoned + flowouts;
                return tot === 0 ? 100 : Math.floor(totWaitingAnsweredWithinSLA / tot * 100);
            
            case 3:
                tot = totWaitingAnswered + totAbandoned + totShortAbandoned
                return tot === 0 ? 100 : Math.floor(totWaitingAnsweredWithinSLA / tot * 100);
            
            case 4:
                tot = totWaitingAnswered + totAbandoned + flowouts + totShortAbandoned;
                return tot === 0 ? 100 : Math.floor(totWaitingAnsweredWithinSLA / tot * 100);
        }
    }

    if (loading) {
        return (
            <Stack>
                <Skeleton height={100} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
            </Stack>
        )
    }

    return (
        <Table aria-label="calls">
            <TableHead>
                <TableRow>
                    <TableCell align="center" sx={styles.headerCell}>&nbsp;</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >SLA</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Call Waiting")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("On a Call")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Answered")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Abandon")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Decline")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Avg Waiting")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Avg Consult")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Max Waiting")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Max Consult")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Agents Logged In")}</TableCell>
                    <TableCell align="center" sx={styles.headerCell} >{t("Agents Ready")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    queueStats.map(item => {
                        const avgWaiting = item.totWaitingAnswered === 0 ? 0 : Math.floor(item.totWaitingTime / item.totWaitingAnswered / 60);
                        const avgConsult = item.totAnswered === 0 ? 0 : Math.floor(item.totConsultTime / item.totAnswered / 60);
                        const queue = queues.find(x => x.id === item.queueId);
                        const sla = getQueueSLA(item, queue?.formular);
                        return (
                            <TableRow hover key={item.queueId}>
                                <TableCell ><Typography className={getClassName(sla, queue?.warning)}>{item.name}</Typography> </TableCell>
                                <TableCell align="center">{`${sla}${sla === 'N/A' ? '' : '%'}`}</TableCell>
                                <TableCell align="center">{item.currentWaiting}</TableCell>
                                <TableCell align="center">{item.currentAnswer}</TableCell>
                                <TableCell align="center">{item.totAnswered + item.totTransfered}</TableCell>
                                <TableCell align="center">{item.totAbandoned + item.totShortAbandoned}</TableCell>
                                <TableCell align="center">{item.totDeclined}</TableCell>
                                <TableCell align="center">{avgWaiting}</TableCell>
                                <TableCell align="center">{avgConsult}</TableCell>
                                <TableCell align="center">{Math.floor(item.maxWaitingTime / 60)}</TableCell>
                                <TableCell align="center">{Math.floor(item.maxConsultTime / 60)}</TableCell>
                                <TableCell align="center">{queueUserStats[item.queueId]?.cur_users}</TableCell>
                                <TableCell align="center">{queueUserStats[item.queueId]?.avai_users}</TableCell>
                            </TableRow>)
                    })
                }
            </TableBody>
        </Table>
    );
}